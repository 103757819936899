import { FC } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { AuthLayout } from './routes/authLayout';
import { BaseLayout } from './routes/layout';
import { Home } from './routes/page';
import { CompanyReportsPage } from './routes/reports/companies/page';
import { EnvironmentReportsPage } from './routes/reports/environment/page';
import { Privacy } from './routes/privacy/page';

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

const Routes = () => {
  const routes = useRoutes([
    {
      element: <BaseLayout />,
      children: [
        { element: <Home />, path: '/' },
        // Items only available after
        {
          path: '',
          element: <AuthLayout />,
          children: [
            {
              path: '/reports',
              children: [
                { element: <CompanyReportsPage />, path: 'companies' },
                { element: <EnvironmentReportsPage />, path: 'environment' },
              ],
            },
          ],
        },
        {
          path: '/privacy',
          element: <Privacy />,
        },
      ],
    },
  ]);
  return routes;
};
