import { sorter } from '@/lib';
import { EnvironmentReporthResult } from '@/lib/types/reports';
import { BaseTableComponentProps } from '@/lib/types/table';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface EnvironmentReportsTableProps extends BaseTableComponentProps<EnvironmentReporthResult> {}

export const EnvironmentReportsTable: React.FunctionComponent<EnvironmentReportsTableProps> = ({
  items,
  pagination,
  isLoading,
  additionalColumns = [],
  onTableChange,
}) => {
  const { t } = useTranslation('reports');

  const columns = useMemo<ColumnType<EnvironmentReporthResult>[]>(() => {
    const getBaseColumn = (key: string) => ({ key: key, dataIndex: key, title: t(`fields.environmentReportResult.${key}`) });

    return [
      getBaseColumn('uoNumber'),
      getBaseColumn('name'),
      getBaseColumn('address'),
      getBaseColumn('city'),
      { ...getBaseColumn('status'), render: (status: string) => status == 'True' ? 'Goedgekeurd' : 'Afgekeurd' },
      ...additionalColumns,
    ];
  }, [t, additionalColumns]);

  const sortedData = useMemo(
    () =>
      items.sort(
        sorter.sortMultipleProperties([
          { property: x => x.uoNumber, asc: true, emptyOnTop: true },
          { property: x => x.uoNumber, asc: true, emptyOnTop: true },
        ])
      ),
    [items]
  );

  return (
    <Table loading={isLoading} rowKey="teeltplanId" dataSource={sortedData} size="small" columns={columns} pagination={pagination} onChange={onTableChange} />
  );
};
