import { QueryClient, useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { PagedResponse } from '../types/core';
import { EnvironmentReporthResult, EnvironmentReportsFilter, ReviewEnvReportInput } from '../types/reports';
import { createFilterParams, fetchAuthenticated } from '../utils';

const QUERY_KEY = ['reports'];
const BASE_URL = import.meta.env.VITE_REPORT_API_URL;

export const useGetEnvironmentReports = (params?: EnvironmentReportsFilter): UseQueryResult<PagedResponse<EnvironmentReporthResult>, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'environmentReportOverview', params],
    queryFn: () => fetchAuthenticated(`${BASE_URL}/rapportages/milieu?${createFilterParams({ ...params })}`),
  });

export const useGetEnvironmentReport = (teeltPlanId?: number): UseQueryResult<Blob | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'environmentReports', teeltPlanId],
    retry: false,
    queryFn: () => (teeltPlanId ? fetchAuthenticated<Blob>(`${BASE_URL}/rapportages/milieu/${teeltPlanId}`, 'blob', {}, 120_000) : Promise.reject()),
    enabled: false, // Disabled so it only works on refetch
  });

export const useGetCompaniesReport = (year?: number): UseQueryResult<Blob | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'companies', year],
    retry: false,
    queryFn: () => (year ? fetchAuthenticated<Blob>(`${BASE_URL}/rapportages/bedrijven/${year}`, 'blob', {}, 120_000) : Promise.reject()),
    enabled: !!year,
  });

export const useReviewEnvironmentReport = () => {
  const queryClient = new QueryClient();

  return useMutation<ReviewEnvReportInput, Error, ReviewEnvReportInput>({
    mutationFn: (review: ReviewEnvReportInput) =>
      fetchAuthenticated<ReviewEnvReportInput>(`${BASE_URL}/rapportages/milieu`, 'json', {
        json: review,
        method: 'PATCH',
      }) as Promise<ReviewEnvReportInput>,
    mutationKey: [...QUERY_KEY],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY] });
    },
  });
};
