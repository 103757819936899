import { useReviewEnvironmentReport } from '@/lib/queries/reports';
import { FormOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio } from 'antd';
import React, { useState } from 'react';

interface ReviewEnvReportButtonProps {
  id: number;
  onOk: () => void;
}

export const ReviewEnvReportButton: React.FunctionComponent<ReviewEnvReportButtonProps> = ({ id, onOk }) => {
  const reviewEnvReport = useReviewEnvironmentReport();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    values.teeltplanId = id;
    values.datumVanVerwerking = new Date();
    reviewEnvReport.mutate(values, {
      onSuccess: () => {
        setIsModalVisible(false);
        onOk();
      },
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Button icon={<FormOutlined />} onClick={showModal} title="Milierapport keuren" />
      <Modal title="Milieurapport keuren" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ paddingBottom: '20px' }}>
          <p> Keur het milieurapport door middel van onderstaande opties en geef extra toelichting wanneer nodig.</p>
        </div>
        <Form form={form} layout="vertical">
          <Form.Item name="status" rules={[{ required: true, message: 'Selecteer een optie' }]} style={{ textAlign: 'center' }}>
            <Radio.Group>
              <Radio.Button value={2}>Goedgekeurd</Radio.Button>
              <Radio.Button value={3}>Afgekeurd</Radio.Button>
              <Radio.Button value={1}>Onvolledig</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="reden" rules={[{ required: false }]}>
            <Input type="text" placeholder="Toelichting" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
