import { Button, Col, DatePicker, Form, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface YearSelectProps {
  onSubmit(year: number);
  isLoading?: boolean;
}

export const YearSelect: React.FunctionComponent<YearSelectProps> = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation('reports');
  const [form] = Form.useForm();

  const onSearchFormFinish = (values: { year?: Dayjs }) => {
    if (!values.year) {
      return false;
    }

    onSubmit(values.year?.year());
  };

  return (
    <Form onFinish={onSearchFormFinish} form={form} initialValues={{ year: dayjs().subtract(1, 'year') }}>
      <Row gutter={8}>
        <Col>
          <Form.Item name="year" noStyle>
            <DatePicker picker="year" />
          </Form.Item>
        </Col>
        <Col>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            {t('actions.submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
