import { useGetEnvironmentReport } from '@/lib/queries/reports';
import { downloadBlob } from '@/lib/utils/downloadBlob';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect } from 'react';

interface DownloadEnvReportButtonProps {
  id: number;
  reportTitle: string;
}

export const DownloadEnvReportButton: React.FunctionComponent<DownloadEnvReportButtonProps> = ({ id, reportTitle }) => {
  const { isLoading, data, refetch } = useGetEnvironmentReport(id);

  useEffect(() => {
    if (data && data.type !== 'text/html') {
      downloadBlob(data, `${reportTitle}.xlsx`);
    }
  }, [reportTitle, data]);

  return <Button loading={isLoading} icon={<DownloadOutlined />} title="Milieurapport downloaden" onClick={() => refetch()} />;
};
