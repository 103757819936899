import { Page } from '@/lib/components/core/pageItems/page';
import { YearSelect } from '@/lib/components/reports/yearSelect';
import { useGetCompaniesReport } from '@/lib/queries/reports';
import { downloadBlob } from '@/lib/utils/downloadBlob';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CompanyReportsPage: React.FunctionComponent = () => {
  const { t } = useTranslation('reports');
  const [year, setYear] = useState<number>();
  const { refetch, isLoading, data } = useGetCompaniesReport(year);

  useEffect(() => {
    if (data && data.type !== 'text/html') {
      downloadBlob(data, `companies_${year}.xlsx`);
    }
  }, [data, year]);

  const onSubmit = (input: number) => {
    if (input !== year) {
      setYear(input);
    } else {
      refetch();
    }
  };

  return (
    <Page title={t('pages.companiesReports.title')}>
      <YearSelect onSubmit={onSubmit} isLoading={isLoading} />
    </Page>
  );
};
