import { CompanySearchFilter } from '@/lib/types/reports';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CompanySearchBarProps {
  onSubmit(values: CompanySearchFilter);
}

export const CompanySearchBar: React.FunctionComponent<CompanySearchBarProps> = ({ onSubmit }) => {
  const { t } = useTranslation('reports');
  const [form] = Form.useForm();

  const onSearchFormFinish = (values: { search?: string; year?: Dayjs }) => {
    onSubmit({ search: values.search?.trim(), year: values.year?.year() });
  };

  return (
    <Form onFinish={onSearchFormFinish} form={form} initialValues={{ year: dayjs().subtract(1, 'year') }}>
      <Row gutter={8}>
        <Col flex={1}>
          <Form.Item name="search" noStyle>
            <Input placeholder={t('companySearch.placeholder') || ''} allowClear />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="year" noStyle>
            <DatePicker picker="year" />
          </Form.Item>
        </Col>
        <Col>
          <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
            {t('actions.search')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
