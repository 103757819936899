import { PageWidget } from '@/lib';
import { Page } from '@/lib/components/core/pageItems/page';
import { CompanySearchBar } from '@/lib/components/reports';
import { DownloadEnvReportButton } from '@/lib/components/reports/downloadEnvReportButton';
import { ReviewEnvReportButton } from '@/lib/components/reports/reviewEnvReportButton';
import { EnvironmentReportsTable } from '@/lib/components/tables/environmentReportsTable';
import { useGetEnvironmentReports } from '@/lib/queries/reports';
import { FilterBase } from '@/lib/types/core';
import { CompanySearchFilter } from '@/lib/types/reports';
import { TablePaginationConfig } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type PageFilter = FilterBase & CompanySearchFilter;

export const EnvironmentReportsPage: React.FunctionComponent = () => {
  const { t } = useTranslation('reports');
  const [filter, setFilter] = useState<PageFilter>({ year: new Date().getFullYear() - 1 });

  const { isLoading, data: environmentReportsResponse, refetch } = useGetEnvironmentReports(filter);

  const onSearch = (values: CompanySearchFilter) => {
    setFilter(cv => ({ ...cv, page: 0, ...values }));
  };

  const onTableChange = (pagination: TablePaginationConfig) => {
    setFilter(cv => ({ ...cv, page: (pagination.current || 1) - 1, pageSize: pagination.pageSize }));
  };

  return (
    <Page title={t('pages.environmentReports.title')}>
      <CompanySearchBar onSubmit={onSearch} />
      <PageWidget title={t('pages.environmentReports.searchResult')}>
        <EnvironmentReportsTable
          isLoading={isLoading}
          items={environmentReportsResponse?.data || []}
          onTableChange={onTableChange}
          pagination={{
            current: (environmentReportsResponse?.currentPage || 0) + 1,
            pageSize: environmentReportsResponse?.pageSize,
            total: environmentReportsResponse?.totalItems,
          }}
          additionalColumns={[
            {
              key: 'actions',
              dataIndex: 'teeltplanId',
              title: '',
              render: (value, row) => (
                <>
                  <DownloadEnvReportButton id={value} reportTitle={`milieurapportage_${row.name}_${filter?.year}.xlsx`} />
                  <ReviewEnvReportButton id={value} onOk={refetch} />
                </>
              ),
            },
          ]}
        />
      </PageWidget>
    </Page>
  );
};
